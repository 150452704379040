.navbar {
    background-color: #333; 
    color: white;
    padding: 10px;
    position: sticky; 
    overflow: auto;
    top: 0; 
    width: 100%;
    z-index: 1000; 
}

.navbar li a:hover {
    background-color: #ddd;
    color: black;
}

.navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    padding-left: 20px;
}

.navbar li {
    float: left;
}

.navbar li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
}

@media (max-width: 768px) {
    .navbar ul {
        flex-direction: column;
        width: 100%;
        display: none;
    }

    .navbar ul.show {
        display: flex;
    }

    .navbar li {
        width: 100%;
    }

    .hamburger {
        display: flex;
    }
}