.profile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  padding: 20px;
  color: #333;
}

.profile-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.profile-content {
  /* background-color: rgba(220, 230, 240, 0.4);  */
  padding: 20px;
  border-radius: 15px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
  width: 100%;
  max-width: 600px;
}

.profile-content h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.profile-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.socials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.socials-list {
  display: flex;
  flex-direction: row;
  gap: 40px;
  list-style: none;
  padding: 0;
}

.socials-list li {
  margin: 15px 0;
}

.socials-list img {
  width: 20px;
  transition: transform 0.3s;
  /* filter: grayscale(100%); */
}

@media (max-width: 768px) {
  .profile {
    flex-direction: column;
    align-items: center;
  }

  .profile-column {
    margin-left: 0;
  }

  .profile-image img {
    height: 130%;
  }

  .profile-content {
    width: 80%;
  }

  .socials-container {
    padding-top: 50px;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .socials-list {
    flex-direction: row;
  }

  .socials-list li {
    margin: 0 5px;
  }
}
