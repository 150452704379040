.experience-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.experience-item {
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background-color: rgba(220, 230, 240, 0.4);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  gap: 20px;
}

.experience-left {
  flex: 0 0 40%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.experience-left h3 {
  font-size: 14px;
}

.experience-left p {
  font-size: 14px;
}

.experience-right {
  flex: 1 1 60%;
}

.experience-left img {
  width: 100px;
  height: 100px; 
  object-fit: cover; 
  margin-bottom: 10px;
}

.experience-left h2,
.experience-left h3,
.experience-left p {
  margin: 0;
}

.experience-left p {
  margin-top: 5px; 
}

.experience-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.experience-right li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .experience-item {
    flex-direction: column;
  }

  .experience-left,
  .experience-right {
    flex: 1 1 100%;
  }

  .experience-left img {
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
  }
}
