.projects-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* padding: 20px; */
    justify-content: center;
    width: 100%;
    align-items: center;
    align-content: center;
  }
  
  .project-item {
    flex: 1 1 25%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 25%;
    align-content: center;
    background-color: rgba(220, 230, 240, 0.4);
    border-radius: 8px;
  }
  
  .project-item img {
    width: 100%;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 40px;
  }
  
  .project-info {
    padding: 15px;
    width: 100%;  
  }
  
  .project-info h3 {
    margin-top: 0;
    color: #333;
  }
  
  .project-info p {
    font-size: 16px;
    color: #3f3e3e;
    line-height: 1.6;
  }

  @media (max-width: 768px) {
    .project-item {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }