html {
  scroll-behavior: smooth;
}

body {
  background: linear-gradient(to right, #ffcb05, #00274c); 
  font-family: Arial, sans-serif;
  color: #333;
}

.App h1 {
  text-align: center;
  color: #333;
  font-size: 250%;
}

.App p {
  font-size: 130%;
}

.content {
  padding: 20px;
  height: 2000px;
  color: #333;
}

.btn {
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  color: black;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.buttonDiv {
  padding-bottom: 20px;
  text-align: center;
}

/* .btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: scale(1.1);
} */

.btn:hover {
  background-color: #4c98ea;
}

.description {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}

.description.expanded {
  max-height: 600px;
}

.description.collapsed {
  max-height: 0;
}

#projects, #experience, #contact, #home {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 30px;
  width: 80%;
  margin: 30px auto;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #333;
}
